import React, { FunctionComponent } from "react";
import {
  BoxItemContainer,
  ImageContainer,
  TextContainer,
} from "components/ui/base/BentoBoxItem/index.style";
import StaticImg from "components/core/StaticImg";
import Heading from "components/core/Typography/Heading";

export interface BentoBoxItemProps {
  title?: string;
  relativePath: string;
  imageAlt: string;
  imagePosition?: "top" | "bottom";
  gridRow?: string;
  gridColumn?: string;
}

const BentoBoxItem: FunctionComponent<BentoBoxItemProps> = ({
  title,
  relativePath,
  imageAlt,
  imagePosition,
  gridRow,
  gridColumn,
}) => (
  <BoxItemContainer imagePosition={imagePosition}>
    <ImageContainer imagePosition={imagePosition}>
      <StaticImg alt={imageAlt} src={relativePath} />
    </ImageContainer>
    <TextContainer imagePosition={imagePosition}>
      {title && (
        <Heading level={5} weight={600}>
          {title}
        </Heading>
      )}
    </TextContainer>
  </BoxItemContainer>
);

export default BentoBoxItem;
