import React, { FunctionComponent } from "react";
import { Col, Container, Row } from "styled-bootstrap-grid";
import { useTranslation } from "react-i18next";
import { PageContainer } from "styles/global.style";
import BentoBoxItem from "components/ui/base/BentoBoxItem";

const BentoBoxMarketingAutomationSolutions: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <PageContainer>
      <Container fluid>
        <Row>
          <Col lg={7} sm={12}>
            <Row>
              <BentoBoxItem
                relativePath={
                  "pages/marketing-automation-tool/marketing-automation.png"
                }
                imageAlt={"Workflows"}
                title={"Powerful Workflows"}
              />
            </Row>
            <Row>
              <BentoBoxItem
                relativePath={
                  "pages/marketing-automation-tool/audience-segmentation.png"
                }
                imageAlt={"Audience Segmentation"}
                title={"Audience Segmentation"}
              />
            </Row>
          </Col>
          <Col lg={5} sm={12}>
            <BentoBoxItem
              relativePath={"pages/marketing-automation-tool/Notification.png"}
              imageAlt={"Engaging Triggers"}
              title={"Engaging Triggers"}
            />
          </Col>
        </Row>
      </Container>
    </PageContainer>
  );
};

export default BentoBoxMarketingAutomationSolutions;
