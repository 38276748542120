import styled from "styled-components";
import { StyledHeading } from "components/core/Typography/Heading/index.style";

const BoxItemContainer = styled.div<{
  imagePosition?: "top" | "bottom";
}>`
  display: flex;
  flex-direction: column;
  align-items: ${(props) =>
    props.imagePosition === "top" ? "center" : "flex-start"};
  text-align: ${(props) => (props.imagePosition === "top" ? "center" : "left")};
  background: ${(props) => props.theme.baseLighten95Color};
  border-radius: ${(props) => props.theme.borderRadius};
  margin-bottom: 16px;
`;

const ImageContainer = styled.div<{ imagePosition?: "top" | "bottom" }>`
  order: ${(props) => (props.imagePosition === "top" ? 0 : 1)};
  padding: 0 5%;
`;

const TextContainer = styled.div<{ imagePosition?: "top" | "bottom" }>`
  order: ${(props) => (props.imagePosition === "top" ? 1 : 0)};
  padding: 16px;
  width: 100%;
  text-align: center;

  ${StyledHeading} {
    margin-bottom: 0;
  }
`;

export { BoxItemContainer, ImageContainer, TextContainer };
